'use client';

import { homeAppPath } from '@29cm/contexts-common-constants';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect } from 'react';

type Props = {
  error: Error & { digest?: string };
};

export default function Error({ error }: Props) {
  useEffect(() => {
    console.error(error);

    Sentry.captureException(error, {
      level: 'error',
      tags: {
        type: 'error-page',
      },
      extra: {
        error,
      },
    });
  }, [error]);

  return (
    <div className="mx-auto my-0 box-border w-full max-w-[1000px] px-[35px] py-[50px] text-left md:box-content md:w-auto md:px-[50px] md:py-[200px]">
      <h2 className="text-title-xxl-medium text-primary">
        오류가 발생했습니다
        <br />
        불편을 드려 죄송합니다
      </h2>

      <p className="mt-[22px] text-xxl text-primary md:mt-[15px] md:text-xl">{error.message}</p>

      <Link
        className="mt-[40px] flex h-[50px] w-[300px] items-center justify-center bg-primary text-xxl-medium text-on-color"
        href={homeAppPath}
      >
        홈으로 가기
      </Link>
    </div>
  );
}
